import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// React icons
import { FaPlay } from 'react-icons/fa';

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// Sections for this page
import ProductSection from "./Sections/ProductSection.jsx";
import TeamSection from "./Sections/TeamSection.jsx";
import WorkSection from "./Sections/WorkSection.jsx";
import VisionMissionSection from "./Sections/VisionMissionSection.jsx";
import BannerLogo from "assets/img/banner-logo.png";
import { Hidden } from "@material-ui/core";
import ValuesSection from "./Sections/ValuesSection.jsx";
import TestimonialSection from "./Sections/TestimonialSection.jsx";
import NewsLetterSection from "./Sections/NewsLetterSection.jsx";
import FooterSection from "./Sections/FooterSection.jsx";
import quotebgbg from "assets/img/qt-bg-bg.png";
import { Link } from "gatsby";

const dashboardRoutes = [];

class LandingPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="AL MIDIAFF INTERNATIONAL SUPPLIES"
          // brand="AL MIDIAFF INTERNATIONAL Co. LLC"
          // brand="AL MIDIAFF"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax filter image={require("assets/img/shaped-logo-banner.png")}>
        {/* <Parallax filter image={require("assets/img/landing-banner.jpg")}> */}
        {/* <Parallax filter image={require("assets/img/shape-5.png")}> */}
          <div className={classes.container}>
            <Hidden smUp>
              <div style={{ height: 160 }}></div>
            </Hidden>
            <GridContainer>
              <GridItem xs={12} sm={9} md={8} style={{textShadow: "2px 2px black"}}>
                <h2 className={classes.title}>We are</h2>
                <h1 className={classes.title}>Innovation, Tools & Technology</h1>
                <h4>
                Al Midiaff International Co. LLC (AMICO) Founded In 1993, Together With Our Customers, 
                <br />
                We Are Accelerating The Pace Of Change For The Industry.
                </h4>
                <br />
                <Link to="/about">
                  <Button
                    color="primary"
                    size="md"
                    rel="noopener noreferrer"
                  >
                    {/* <FaPlay/> */}
                    SEE MORE
                  </Button>
                </Link>
              </GridItem>
              {/* <Hidden only={['sm', 'xs']}>
                <GridItem 
                  md={4} 
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-end"
                >
                  <img src={BannerLogo} width="300" style={{marginBottom: "-11vh"}}/>
                </GridItem> 
              </Hidden> */}
            </GridContainer>
          </div>
        </Parallax>
                <br/>
                <br/>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div style={{background:"#B6A19E",maxWidth: "100%"}}>
            <div className={classes.container} style={{}}>
              <VisionMissionSection />
            </div>
          </div>
          <CustomLinearProgress
            variant="determinate"
            color="primary"
            value={100}
          />
          
          <div style={{backgroundSize:"cover",backgroundImage:"url(" + quotebgbg + ")"}}>
          <div className={classes.container} style={{padding:"0"}}>
            <ValuesSection />
            {/* <TeamSection />
            <WorkSection /> */}
          </div>
            <div className={classes.container} style={{marginTop:"4em"}}>
              <TestimonialSection />
            </div>
          </div>
          <div style={{background:"#6C648B",width: "100%"}}>
            <div className={classes.container} >
              <NewsLetterSection />
            </div>
          </div>
          <div style={{background:"#B6A19E", minHeight:"2em"}}></div>
          <FooterSection />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
