import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
import { Typography } from "@material-ui/core";

class ValuesSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.valuseSection}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title} style={{fontSize:"60px", textAlign:"center", color:"#6C648B"}}>Our Values</h2>            
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={4} md={2} style={{display: 'flex'}}>
              <Card elevation={0} className={classes.root} style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                <CardActionArea>
                  <CardContent>
                    <p style={{fontSize:"160px", color:"#000"}}>
                      C
                    </p>
                    <Typography style={{color:"#6C648B", fontWeight: "400"}} className={classes.title} gutterBottom variant="h5" component="h2">
                      Client Focused
                    </Typography>
                    <p className={classes.description} style={{textAlign:"justify"}}>
                    Our Success delivers from a deep understanding of our clients to whom all of 
                    AMICO is committed to deliver exceptional service and value.
                    </p>
                  </CardContent>
                </CardActionArea>
              </Card> 
            </GridItem>
            <GridItem xs={12} sm={4} md={2} style={{display: 'flex'}}>
              <Card elevation={0} className={classes.root} style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                <CardActionArea>
                  <CardContent>
                    <p style={{fontSize:"160px", color:"#000"}}>
                      L
                    </p>
                    <Typography style={{color:"#6C648B", fontWeight: "400"}} className={classes.title} gutterBottom variant="h5" component="h2">
                      Leadership
                    </Typography>
                    <p className={classes.description} style={{textAlign:"justify"}}>
                    We Lead from the front, displaying our integrity and using facts to support our straight 
                    talk. We create our environment for positive change built on collaboration and trust.
                    </p>
                  </CardContent>
                </CardActionArea>
              </Card> 
            </GridItem>
            <GridItem xs={12} sm={4} md={2} style={{display: 'flex'}}>
              <Card elevation={0} className={classes.root} style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                <CardActionArea>
                  <CardContent>
                    <p style={{fontSize:"160px", color:"#000"}}>
                      E
                    </p>
                    <Typography style={{color:"#6C648B", fontWeight: "400"}} className={classes.title} gutterBottom variant="h5" component="h2">
                      Excellence
                    </Typography>
                    <p className={classes.description} style={{textAlign:"justify"}}>
                    We insist on Excellence in all we do for our clients and our selves, striving always 
                    for recognition among the leaders in our industry.
                    </p>
                  </CardContent>
                </CardActionArea>
              </Card> 
            </GridItem>
            <GridItem xs={12} sm={4} md={2} style={{display: 'flex'}}>
              <Card elevation={0} className={classes.root} style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                <CardActionArea>
                  <CardContent>
                    <p style={{fontSize:"160px", color:"#000"}}>
                      A
                    </p>
                    <Typography style={{color:"#6C648B", fontWeight: "400"}} className={classes.title} gutterBottom variant="h5" component="h2">
                      Aspiration
                    </Typography>
                    <p className={classes.description} style={{textAlign:"justify"}}>
                    We Aspire Individually and Collectively to be more tomorrow than we are today.
                    </p>
                  </CardContent>
                </CardActionArea>
              </Card> 
            </GridItem>
            <GridItem xs={12} sm={4} md={2} style={{display: 'flex'}}>
              <Card elevation={0} className={classes.root} style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                <CardActionArea>
                  <CardContent>
                    <p style={{fontSize:"160px", color:"#000"}}>
                      R
                    </p>
                    <Typography style={{color:"#6C648B", fontWeight: "400"}} className={classes.title} gutterBottom variant="h5" component="h2">
                      Results
                    </Typography>
                    <p className={classes.description} style={{textAlign:"justify"}}>
                    We Accept Individually Responsibility for our commitments and expect to be accountable for results.
                    </p>
                  </CardContent>
                </CardActionArea>
              </Card> 
            </GridItem>

          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ValuesSection);
